<template>
    <v-layout row class="pa-3 mb-2">
        <v-flex xs12 sm6 offset-sm3>
            <v-card flat>
                <v-card-title class="justify-center subheading pa-0"><b>{{ $t("takeoutchange.title")}}</b></v-card-title>
                <v-layout row wrap class="mb-5">
                    <v-flex xs12 v-for="box_id in boxIds" :key="box_id">
                        <v-list subheader>
                            <v-subheader>{{ packages.find((a) => a.box_id === box_id).box_name }}({{ box_id }})</v-subheader>
                            <v-list-tile v-for="pdata in packages.filter((a) => a.box_id === box_id)" :key="pdata.track_no">
                                <v-list-tile-action>
                                    <v-checkbox color="indigo" v-model="pdata.selected"></v-checkbox>
                                </v-list-tile-action>

                                <v-list-tile-content>
                                    <v-list-tile-title>{{ $t("takeoutchange.cell") }} {{ pdata.door_id }}</v-list-tile-title>
                                    <v-list-tile-sub-title>{{ $t("takeoutchange.phone-number") }}: {{ pdata.phone || "" }}</v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </v-list>
                    </v-flex>
                </v-layout>
                <v-layout row wrap>
                    <v-flex xs12>
                        <v-btn
                            large
                            block
                            class="ma-1 white--text"
                            color="purple darken-2"
                            @click="changeReceiver"
                            :disabled="packages.filter((a) => a.selected).length === 0"
                            >{{ $t("takeoutchange.change") }}</v-btn
                        >
                    </v-flex>
                    <v-flex xs12>
                        <v-btn large block class="ma-1 white--text" color="black darken-2" @click="leave">{{ $t("takeoutchange.cancel") }}</v-btn>
                    </v-flex>
                </v-layout>

                <v-dialog persistent v-model="emptyDialog" width="250">
                    <v-card class="text-xs-center">
                        <v-card-text class="title">
                            {{ dialogMessage }}
                        </v-card-text>
                        <v-card-actions class="justify-center">
                            <v-btn block class="subheading" color="primary" @click="leave()">{{ $t("putIn.close") }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err" :timeout="dialog.timeout"></waiting-dialog>
                <receiver-dialog
                    :show.sync="receiverDialog.show"
                    :msg="receiverDialog.msg"
                    :primary-btn="receiverDialog.primaryBtn"
                    :secondary-btn="receiverDialog.secondaryBtn"
                    @primary="sendChangeReceiver"
                    :receiverPhone.sync="receiverPhone"
                ></receiver-dialog>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import axios from "axios";
import store, { HostUrl } from "@/store";
import BoxIdWatcherMixin from "@/mixins/BoxIdWatcher";
import ControlResultWatcher from "@/mixins/ControlResultWatcher";
import PutinMixin from "@/mixins/PutinMixin";
import { getAxiosConfig, getTakeoutFriendUrl } from "@/utils/AuthService";
import i18n from "@/i18n";
import ReceiverDialog from "@/components/ReceiverDialog.vue";
import ReceiverDialogControl from "@/mixins/ReceiverDialogControl.vue";
import WaitingDialog from "@/components/WaitingDialog";
import WaitDialogControl from "@/mixins/WaitDialogControl";
export default {
    name: "Putin",
    components: { ReceiverDialog, WaitingDialog },
    mixins: [BoxIdWatcherMixin, ControlResultWatcher, PutinMixin, ReceiverDialogControl, WaitDialogControl],
    data() {
        return {
            stepNo: 1,
            titles: [i18n.t("menu.buttons.take-out-fix")],
            headers: [
                {
                    sortable: false,
                    width: "10px",
                },
                { text: i18n.t("takeoutchange.box-id"), value: "box_id" },
                { text: i18n.t("takeoutchange.box-name"), value: "box_name" },
                { text: i18n.t("takeoutchange.cell"), value: "door_id" },
                { text: i18n.t("takeoutchange.phone-number"), value: "phone" },
            ],
            emptyDialog: false,
            dialogMessage: "",
            packages: [],
            receiverPhone: ""
        };
    },
    computed: {
        boxIds() {
            if (this.packages.length === 0) {
                return [];
            }
            const map = new Map();
            for (const item of this.packages) {
                if (!map.has(item.box_id)) {
                    map.set(item.box_id, true); // set any value to Map
                }
            }
            console.log(map.keys());
            return Array.from(map.keys());
        }
    },
    methods: {
        async getPackageStatus() {
            let url = getTakeoutFriendUrl()
            if (store.getters.user.id === 0 || url === null || url === undefined) {
                return;
            }
            this.showDialog(i18n.t("takeout.get-list"), i18n.t("takeout.fail-get-list"));
            let self = this;
            await axios
                .get(HostUrl + url, getAxiosConfig())
                .then(function(response) {
                    console.log(response);
                    const packages = response.data.packages;
                    let packageArray = [];
                    for (const track_no in packages) {
                        const { box_id, box_name, door_id, phone } = packages[track_no];
                        packageArray.push({
                            selected: true,
                            track_no,
                            box_id,
                            box_name,
                            door_id,
                            phone,
                        });
                    }
                    self.packages = packageArray;
                    self.closeDialog();
                })
                .catch(function(error) {
                    console.log("error", error);
                });
        },
        changeReceiver() {
            this.showRecevierDialog(i18n.t("takeoutchange.change"), i18n.t("putIn.ok"), i18n.t("putIn.cancel"));
            // this.closeReceiverDialogFunc = this.sendChangeReceiver;
        },
        async sendChangeReceiver() {
            let self = this;
            let url = HostUrl + "/api/box/takeout/friend";
            let payload = {
                client_id: store.getters.clientId,
                friend_phone: self.receiverPhone,
                packages: {
                    // [this.boxId]: [this.trackNo],
                },
            };
            const changeList = self.packages.filter((a) => a.selected && a.phone !== payload.friend_phone);
            if (changeList.length === 0) {
                store.commit("setSnackBar", {
                    message: i18n.t("takeoutchange.zero-package"),
                    color: "error",
                });
                return;
            }
            changeList.forEach((pdata) => {
                if (!payload.packages[pdata.box_id]) {
                    payload.packages[pdata.box_id] = [];
                }
                payload.packages[pdata.box_id].push(pdata.track_no);
            });

            const waitPromise = new Promise((resolve) => {
                self.showDialog(i18n.t("putIn.adding"), i18n.t("putIn.failed-add-recipient"), 30000);
                setTimeout(resolve, 1000);
            });
            const updatePromise = axios
                .post(url, payload, getAxiosConfig())
                .then(function(response) {
                    if (response.data.code === 0) {
                        if (response.data.message === "請朋友登入取件") {
                            response.data.message = i18n.t("putIn.add-success") + payload.friend_phone + i18n.t("putIn.to-pick-up");
                        }
                        if (response.data.message === "請朋友註冊後，登入取件") {
                            response.data.message = i18n.t("putIn.add-success-register") + payload.friend_phone + i18n.t("putIn.log-to-pick-up");
                        }
                        store.commit("setSnackBar", {
                            message: response.data.message,
                            color: "success",
                        });
                    } else {
                        store.commit("setSnackBar", {
                            message: response.data.message,
                            color: "error",
                        });
                    }
                })
                .catch(function(error) {
                    console.error(error);
                    store.commit("setSnackBar", {
                        message: i18n.t("putIn.failed-add-recipient"),
                        color: "error",
                    });
                });
            await Promise.all([waitPromise, updatePromise]).then(() => {
                self.closeDialog();
            });
            await self.getPackageStatus();
        },
        leave() {
            this.init();
            this.$router.push(`/${i18n.locale}/menu`);
        },
    },
    activated() {
        this.getPackageStatus();

        // setTimeout(function(){
        //     store.commit('setTargetBoxId', '099001')
        // }, 1000)
    },
};
</script>

<style scoped>
.nobull {
    list-style-type: none;
    padding-left: 0px;
}
.bold {
    font-weight: bold;
    font-size: 1.25rem;
}
</style>
